import React from 'react'

const hyperItalics = <span>Whelton PK, Carey RM, Aronow WS, et al. 2017 ACC/AHA/AAPA/ABC/ACPM/AGS/APhA/ASH/ASPC/NMA/PCNA guideline for the prevention, detection, evaluation, and management of high blood pressure in adults: executive summary: a report of the American College of Cardiology/American Heart Association Task Force on Clinical Practice Guidelines [published correction appears in <span style={{fontStyle: 'italic'}}>Hypertension</span>. 2018;71(6):e136-e139] [published correction appears in <span style={{fontStyle: 'italic'}}>Hypertension</span>. 2018;72(3):e33].</span>
const arnettDK = <span>Arnett DK, Blumenthal RS, Albert MA, et al. 2019 ACC/AHA guideline on the primary prevention of cardiovascular disease: executive summary: a report of the American College of Cardiology/American Heart Association Task Force on Clinical Practice Guidelines [published correction appears in <em>Circulation.</em> 2019;140(11):e647-e648] [published correction appears in <em>Circulation.</em> 2020;141(4):e59] [published correction appears in <em>Circulation.</em> 2020;141(16):e773]. <em>Circulation.</em> 2019;140(11):e563-e595.</span>

export const references = [
  {
    content:
      'Black J, Reaven NL, Funk SE, et al. Medical comorbidity in narcolepsy: findings from the Burden of Narcolepsy Disease (BOND) study.',
    source: ' Sleep Med. ',
    info: '2017;33:13-18.',
  },
  {
    content:
      'Cohen A, Mandrekar J, St Louis EK, Silber MH, Kotagal S. Comorbidities in a community sample of narcolepsy.',
    source: ' Sleep Med. ',
    info: '2018;43:14-18.',
  },
  {
    content:
      'Ohayon MM. Narcolepsy is complicated by high medical and psychiatric comorbidities: a comparison with the general population.',
    source: ' Sleep Med. ',
    info: ' 2013;14(6):488-492.',
  },
  {
    content: hyperItalics,
    source: ' Hypertension. ',
    info: '2018;71(6):1269-1324.',
  },
  {
    content:
      'Jackson SL, King SM, Zhao L, Cogswell ME. Prevalence of excess sodium intake in the United States—NHANES, 2009-2012.',
    source: ' MMWR Morb Mortal Wkly Rep. ',
    info: '2016;64(52):1393-1397.',
  },
  {
    content:
      'Benjamin EJ, Muntner P, Alonso A, et al; American Heart Association Council on Epidemiology and Prevention Statistics Committee and Stroke Statistics Subcommittee. Heart disease and stroke statistics—2019 update: a report from the American Heart Association.',
    source: ' Circulation. ',
    info: '2019;139(10):e56-e528.',
  },
  {
    content:
      "Lloyd-Jones DM, Hong Y, Labarthe D, et al. Defining and setting national goals for cardiovascular health promotion and disease reduction: the American Heart Association's strategic Impact Goal through 2020 and beyond.",
    source: ' Circulation. ',
    info: '2010;121(4):586-613.',
  },
  {
    content:
      'Salehi-Abargouei A, Maghsoudi Z, Shirani F, Azadbakht L. Effects of Dietary Approaches to Stop Hypertension (DASH)-style diet on fatal or nonfatal cardiovascular diseases—incidence: a systematic review and meta-analysis on observational prospective studies.',
    source: ' Nutrition. ',
    info: '2013;29(4):611–618.',
  },
  {
    content:
      'Ma Y, He FJ, Sun Q, et al. 24-Hour urinary sodium and potassium excretion and cardiovascular risk',
    source: ' N Engl J Med. ',
    info: '2022;386(3):252-263.',
  },
  {
    content:
      'Quan SF. Sleep disturbances and their relationship to cardiovascular disease.',
    source: ' Am J Lifestyle Med. ',
    info: '2009;3(1 suppl):55s-59s.',
  },
  {
    content:
      'Centers for Disease Control and Prevention. Sleep and sleep disorders.',
    source: ' Sleep and Chronic Disease. ',
    info: 'https://www.cdc.gov/sleep/about_sleep/chronic_disease.html. Page last reviewed: August 8, 2018.',
  },
  {
    content:
      'McAlpine CS, Kiss MG, Rattik S, et al. Sleep modulates haematopoiesis and protects against atherosclerosis.',
    source: ' Nature. ',
    info: '2019;566(7744):383-387.',
  },
  {
    content:
      'Dauvilliers Y, Jaussent I, Krams B, et al. Non-dipping blood pressure profile in narcolepsy with cataplexy.',
    source: ' PLoS One. ',
    info: '2012;7(6):e38977.',
  },
  {
    content:
      'Grimaldi D, Calandra-Buonaura G, Provini F, et al. Abnormal sleep-cardiovascular system interaction in narcolepsy with cataplexy: effects of hypocretin deficiency in humans.',
    source: ' Sleep. ',
    info: '2012;35(4):519-528.',
  },
  {
    content:
      '',
    source: 'WHO Guideline: Sodium Intake for Adults and Children. ',
    info: 'World Health Organization; 2012.',
  },
  {
    content:
      'Elliott P, Stamler J, Nichols R, et al. Intersalt revisited: further analyses of 24 hour sodium excretion and blood pressure within and across populations.',
    source: ' BMJ. ',
    info: '1996;312(7041):1249-1253.',
  },
  {
    content:
      'Mente A, O’Donnell MJ, Rangarajan S, et al; PURE Investigators. Association of urinary sodium and potassium excretion with blood pressure.',
    source: ' N Engl J Med. ',
    info: '2014;371(7):601-611.',
  },
  {
    content:
      'Lawes CM, Vander Hoorn S, Rodgers A; International Society of Hypertension. Global burden of blood-pressure-related disease, 2001.',
    source: ' Lancet. ',
    info: '2008;371(9623);1513-1518.',
  },
  {
    content:
      'Kannel WB. Blood pressure as a cardiovascular risk factor: prevention and treatment.',
    source: ' JAMA. ',
    info: '1996;275(20):1571-1576.',
  },
  {
    content:
      'Kokubo Y, Matsumoto C. Hypertension is a risk factor for several types of heart disease: review of prospective studies.',
    source: ' Adv Exp Med Biol. ',
    info: '2017;956:419-426.',
  },
  {
    content:
      'Institute of Medicine.',
    source: ' Sodium Intake in Populations: Assessment of Evidence. ',
    info: 'Washington, DC: National Academies Press; 2013.',
  },
  {
    content:
      'Gardener H, Rundek T, Wright CB, Elkind MS, Sacco RL. Dietary sodium and risk of stroke in the Northern Manhattan study.',
    source: ' Stroke. ',
    info: '2012;43(5):1200-1205.',
  },
  {
    content:
      'Rodriguez CJ, Bibbins-Domingo K, Jin Z, Daviglus ML, Goff DC Jr, Jacobs DR Jr. Association of sodium and potassium intake with left ventricular mass: coronary artery risk development in young adults.',
    source: ' Hypertension. ',
    info: '2011;58(3):410-416.',
  },
  {
    content: 'Strazzullo P, D’Elia L, Kandala NB, Cappuccio FP. Salt intake, stroke, and cardiovascular disease: meta-analysis of prospective studies.',
    source: ' BMJ. ',
    info: '2009;339:b4567.',
  },
  {
    content: 'US Department of Health and Human Services and US Department of Agriculture.',
    source: ' 2015-2020 Dietary Guidelines for Americans. ',
    info: '8th Edition. December 2015. Available at http://health.gov/dietaryguidelines/2015/guidelines/.',
  },
  {
    content: 'Farquhar WB, Edwards DG, Jurkovitz CT, Weintraub WS. Dietary sodium and health.',
    source: ' J Am Coll Cardiol. ',
    info: '2015;65(10):1042-1050.',
  },
  {
    content: 'Robinson AT, Edwards DG, Farquhar WB. The influence of dietary salt beyond blood pressure.',
    source: ' Curr Hypertens Rep. ',
    info: '2019;21(6):42.',
  },
  {
    content: 'Quader ZS, Zhao L, Gillespie C, et al. Sodium intake among persons aged ≥2 years—United States, 2013-2014.',
    source: ' MMWR Morb Mortal Wkly Rep. ',
    info: '2017;66(12):324-328.',
  },
  {
    content: 'Perrin G, Korb-Savoldelli V, Karras A, Danchin N, Durieux P, Sabatier B. Cardiovascular risk associated with high sodium-containing drugs: a systematic review.',
    source: ' PLoS One. ',
    info: '2017;12(7):e0180634.',
  },
  {
    content: 'Voluntary Sodium Reduction Goals: Target Mean and Upper Bound Concentrations for Sodium in Commercially Processed, Packaged, and Prepared Foods: Guidance for Industry. US Department of Health and Human Services, Food and Drug Administration, Center for Food Safety and Applied Nutrition;',
    source: '',
    info: ' 2021.',
  },
  {
    content: 'Bibbins-Domingo K, Chertow GM, Coxson PG, et al. Projected effect of dietary salt reductions on future cardiovascular disease.',
    source: ' N Engl J Med. ',
    info: '2010;362(7):590-599.',
  },
  {
    content: 'National Academies of Sciences, Engineering, and Medicine; Health and Medicine Division; Food and Nutrition Board; Committee to Review the Dietary Reference Intakes for Sodium and Potassium.',
    source: ' Dietary Reference Intakes for Sodium and Potassium. ',
    info: 'Washington, DC: National Academies Press; 2019.',
  },
  {
    content: arnettDK,
    source: '',
    info: '',
  },
]
